import {useBillerConfig} from 'lib/appConfig/useBillerConfig';
import {GetAuthKeyQuery} from 'lib/graphql/API';
import {getSearchParamValue} from 'lib/navigation/routes';
import {IframeCardType} from 'payble-shared';
import {
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

type EventsWithNoData =
  | 'load'
  | 'focus'
  | 'blur'
  | 'change'
  | 'expired'
  | 'cvvFocus'
  | 'cvvBlur';
type EventsWithData =
  | 'validate'
  | 'expired'
  | 'cardTypeChange'
  | 'tokenize'
  | 'error'
  | 'notice';

type TokenExIframeEventHandler = (
  event: EventsWithNoData | EventsWithData,
  callback: (data: any) => void
) => void;

type TokenExReference = {
  on: TokenExIframeEventHandler;
  load(): void;
  focus(): void;
  blur(): void;
  cvvFocus(): void;
  cvvBlur(): void;
  reset(): void;
  remove(): void;
  validate(): void;
  validateConfig(): void;
  tokenize(): void;
};

type TokenExIframeOptions = {
  styles?: {
    base: string;
    focus: string;
    error: string;
    placeholder: string;
    cvv: {
      base: string;
      focus: string;
      error: string;
    };
  };
  inputType?: 'text' | 'number' | 'tel';
  enablePrettyFormat?: boolean;
  debug?: boolean;
  placeholder?: string;
  allowUnknownCardTypes?: boolean;
  pci: boolean;
  cvv: boolean;
  cvvContainerID: string;
  origin: string;
  timestamp: string;
  tokenExID: string;
  tokenScheme: 'PCI';
  authenticationKey: string;
  enableValidationOnBlur?: boolean;
  returnKhash?: boolean;
  inputMaxLength?: number;
  title?: string;
  enableValidateOnBlur?: boolean;
  enableValidateOnKeyUp?: boolean;
  expiresInSeconds?: number;
};

interface TokenExIframe {
  new (
    element: HTMLDivElement | string,
    options: TokenExIframeOptions
  ): TokenExReference;
}

declare global {
  interface Window {
    TokenEx: {
      Iframe: TokenExIframe;
    };
  }
}

type UseTokenExIframeOptions = {
  ref: React.ForwardedRef<TokenExCreditCardFormRef>;
  cardId?: string;
  cvvId?: string;
  onBlur?: () => void;
  onCvvBlur?: () => void;
  onValidate?: (data: any) => void;
  onError?: (data: any) => void;
  data: Omit<GetAuthKeyQuery['authKey'], '__typename'>;
  refetchAuthKey: () => void;
};

export type TokenExCreditCardFormRef = {
  tokenize: () => Promise<any>;
  focus: () => void;
  blur: () => void;
  cvvFocus: () => void;
  cvvBlur: () => void;
  validate: () => void;
};

export const TOKENEX_CARD_ID = 'tokenex-card-element';
export const TOKENEX_CVV_ID = 'tokenex-cvv-element';

const IFRAME_EXPIRES_IN_SECONDS = 1200; // 1 (min) - 1200 (max) (20 minutes) is the default
const TOKENIZE_TIMEOUT_IN_MS = 10_000;

const baseInputStyles = `
    font-family: sans-serif;
    color: #666;
    font-size: 16px;
    line-height: 1.25rem;
    border-style: none;
    border-color: transparent;
    border-width: 0;
    background-color: transparent;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
`;

const ccNumberInputStyles = `
  ${baseInputStyles}
  width: 97%;
`;

const cvvInputStyles = `
  ${baseInputStyles}
  width: 82%;
`;

export const loadScript = async (
  environment: 'test' | 'production'
): Promise<void> => {
  return new Promise((resolve, reject) => {
    function attachScriptEvents(script: HTMLElement) {
      script.addEventListener('load', () => {
        resolve();
      });
      script.addEventListener('error', () => {
        reject();
      });
    }
    const existingScript = document.getElementById(`tokenex${environment}`);
    if (existingScript) {
      if (window.TokenEx?.Iframe) {
        resolve();
      } else {
        attachScriptEvents(existingScript);
      }
    } else {
      const domain =
        environment === 'test' ? 'test-htp.tokenex.com' : 'htp.tokenex.com';
      const script = document.createElement('script');
      script.src = `https://${domain}/Iframe/iframe-v3.79.min.js`;
      script.id = `tokenex${environment}`;
      script.crossOrigin = 'anonymous';
      attachScriptEvents(script);
      document.body.appendChild(script);
    }
  });
};

export const useTokenexIframe = (options: UseTokenExIframeOptions) => {
  const {data, refetchAuthKey} = options;

  const billerConfig = useBillerConfig();

  const iframeExpiresInSeconds = getSearchParamValue('iframeExpiresInSeconds');
  const expiresInSeconds = parseInt(
    iframeExpiresInSeconds ?? IFRAME_EXPIRES_IN_SECONDS.toString(),
    10
  );

  // All possible cards 'masterCard', 'visa', 'americanExpress', 'jcb', 'diners'
  const acceptedCardTypes = billerConfig.acceptedCardTypes;
  const [frameLoaded, setFrameLoaded] = useState<boolean>(false);
  const [frameFocused, setFrameFocused] = useState<boolean>(false);
  const [cvvFocused, setCvvFocused] = useState<boolean>(false);
  const [__frameValid, setFrameValid] = useState<boolean>(false);
  const [frameError, setFrameError] = useState<string | null>(null);
  const [cvvError, setCvvError] = useState<string | null>(null);
  const [frameFirstBlur, setFrameFirstBlur] = useState<boolean>(false);
  const [cvvFirstBlur, setCvvFirstBlur] = useState<boolean>(false);
  const [scriptLoaded, setScriptLoaded] = useState<boolean>(
    !!window.TokenEx?.Iframe
  );
  const [__scriptError, setScriptError] = useState<string | undefined>(
    undefined
  );
  const containerRef = useRef<HTMLDivElement>(null);
  const [tokenEx, setTokenEx] = useState<TokenExReference | undefined>(
    undefined
  );

  const [cardType, setCardType] = useState<IframeCardType>('unknown');
  const environment = options.data.environment as 'test' | 'production';
  const cardId = options.cardId || TOKENEX_CARD_ID;
  const cvvId = options.cvvId || TOKENEX_CVV_ID;
  const {onBlur, onCvvBlur, onError, onValidate, ref} = options || {};

  useEffect(() => {
    const scriptLoad = async () => {
      await loadScript(environment);
      setScriptLoaded(true);
    };

    scriptLoad().catch(error => {
      console.error(error);
      setScriptError(error.message);
    });
  }, [environment, setScriptLoaded]);

  useLayoutEffect(() => {
    if (
      containerRef?.current &&
      scriptLoaded &&
      data &&
      window.TokenEx?.Iframe
    ) {
      const {origin, timestamp, tokenExId, authKey} = data;

      setFrameLoaded(false);
      setFrameError(null);
      onValidate?.({isValid: true});

      const tex = new window.TokenEx.Iframe(cardId, {
        styles: {
          base: ccNumberInputStyles,
          focus: baseInputStyles,
          error: baseInputStyles,
          placeholder: '',
          cvv: {
            base: cvvInputStyles,
            focus: baseInputStyles,
            error: baseInputStyles,
          },
        },
        origin,
        timestamp,
        tokenExID: tokenExId,
        tokenScheme: 'PCI',
        authenticationKey: authKey,
        pci: true,
        cvv: true,
        cvvContainerID: cvvId,
        enablePrettyFormat: true,
        placeholder: '1234 1234 1234 1234',
        allowUnknownCardTypes: false,
        enableValidationOnBlur: true,
        inputMaxLength: 16,
        title: 'Card Number',
        debug: window.location.protocol === 'http:',
        enableValidateOnBlur: true,
        enableValidateOnKeyUp: true,
        expiresInSeconds,
      });

      /**
       * NOTE: Tokenex event handlers can only reply to the __LAST__ event
       * handler provided to `.on()`
       **/
      tex.on('blur', () => {
        setFrameFocused(false);
        setFrameFirstBlur(true);
        onBlur?.();
      });

      tex.on('cvvBlur', () => {
        setCvvFocused(false);
        setCvvFirstBlur(true);
        onCvvBlur?.();
      });

      tex.on('focus', () => {
        setFrameFocused(true);
      });

      tex.on('cvvFocus', () => {
        setCvvFocused(true);
        setCvvError(null);
      });

      tex.on('error', (data: any) => {
        console.log('error', data);
        setFrameFirstBlur(true);
        setFrameError(data.error);
        onError?.(data);

        if (data.error.match(/Session expired/i)) {
          tex.remove();
          refetchAuthKey();
        }
      });

      tex.on('validate', (data: any) => {
        setFrameValid(data.isValid);
        setFrameError(data.isValid ? null : 'Invalid card number');
        setCvvError(data.isCvvValid ? null : 'Invalid CVV');

        if (data.isValid && data.cardType) {
          data = {
            ...data,
            isAccepted: acceptedCardTypes.includes(data.cardType),
          };
        }
        onValidate?.(data);
      });

      tex.on('cardTypeChange', (data: {possibleCardType: string}) => {
        setCardType(data.possibleCardType as IframeCardType);
      });

      tex.on('load', () => {
        setFrameLoaded(true);
      });

      tex.on('expired', () => {
        onValidate?.({isValid: false});
        tex.remove();
        refetchAuthKey();
      });

      tex.load();
      setTokenEx(tex);
    }
  }, [scriptLoaded, data, expiresInSeconds]);

  useImperativeHandle(
    ref,
    () => ({
      tokenize() {
        return new Promise((res, rej) => {
          if (!tokenEx) {
            rej(new Error('TokenEx not initialized'));
            return;
          }
          const timeout = setTimeout(
            () =>
              rej(
                new Error(
                  'There was an issue saving your card. Please try again later.'
                )
              ),
            TOKENIZE_TIMEOUT_IN_MS
          );
          tokenEx.on('tokenize', (data: any) => {
            clearTimeout(timeout);
            res(data);
          });
          tokenEx.tokenize();
        });
      },
      focus() {
        if (!tokenEx) {
          throw new Error('TokenEx not initialized');
        }
        tokenEx.focus();
      },
      blur() {
        if (!tokenEx) {
          throw new Error('TokenEx not initialized');
        }
        tokenEx.blur();
      },
      cvvFocus() {
        if (!tokenEx) {
          throw new Error('TokenEx not initialized');
        }
        tokenEx.cvvFocus();
      },
      cvvBlur() {
        if (!tokenEx) {
          throw new Error('TokenEx not initialized');
        }
        tokenEx.cvvBlur();
      },
      validate() {
        if (!tokenEx) {
          throw new Error('TokenEx not initialized');
        }
        tokenEx.validate();
      },
    }),
    [tokenEx]
  );

  return {
    error: frameFirstBlur && frameError,
    loaded: scriptLoaded && frameLoaded,
    focused: frameFocused,
    cvvError: cvvFirstBlur && !cvvFocused && cvvError,
    cvvFocused,
    cardType,
    containerRef,
  };
};
